const customRoutes = [
	{
		id: 'homeNew',
		path: 'routes/_chat+/homeNew.tsx',
		lngs: [
			{ id: 'en', path: '/en/home' },
			{ id: 'nl', path: '/nl/home' },
		],
	},
	{
		id: 'homeRoot',
		path: 'routes/_chat+/homeNew.tsx',
		lngs: [
			{ id: 'en', path: '/en' },
			{ id: 'nl', path: '/nl' },
		],
	},
	{
		id: 'homeRootEmpty',
		path: 'routes/_chat+/homeNew.tsx',
		lngs: [{ id: 'en', path: '/' }],
	},
	{
		id: 'bigFive',
		path: 'routes/test+/big-five-personality.tsx',
		lngs: [
			{ id: 'en', path: '/en/test/bigFive' },
			{ id: 'nl', path: '/nl/test/groteVijf' },
		],
	},
	{
		id: 'Introduction',
		path: 'routes/_forms+/introduction.tsx',
		lngs: [
			{ id: 'en', path: '/en/introduction' },
			{ id: 'nl', path: '/nl/introductie' },
		],
	},
	{
		id: 'usersProfile',
		path: 'routes/users+/$username.tsx',
		lngs: [
			{ id: 'en', path: '/en/user/:param' },
			{ id: 'nl', path: '/nl/gebruiker/:param' },
		],
	},
	{
		id: 'usersProfileNotes',
		path: 'routes/users+/$username_+/notes.tsx',
		lngs: [
			{ id: 'en', path: '/en/user/:param/notes' },
			{ id: 'nl', path: '/nl/gebruiker/:param/notities' },
		],
	},
	{
		id: 'intakeUser',
		path: 'routes/_forms+/intake.tsx',
		lngs: [
			{ id: 'en', path: '/en/intake' },
			{ id: 'nl', path: '/nl/intake' },
		],
	},
	{
		id: 'contactWiser',
		path: 'routes/contact.tsx',
		lngs: [
			{ id: 'en', path: '/en/contact' },
			{ id: 'nl', path: '/nl/contact' },
		],
	},
	{
		id: 'aboutWiser',
		path: 'routes/_marketing+/about.tsx',
		lngs: [
			{ id: 'en', path: '/en/about' },
			{ id: 'nl', path: '/nl/over-ons' },
		],
	},
	{
		id: 'pricing',
		path: 'routes/_marketing+/pricing.tsx',
		lngs: [
			{ id: 'en', path: '/en/pricing' },
			{ id: 'nl', path: '/nl/prijzen' },
		],
	},
	// Provider
	{
		id: 'authProvider',
		path: 'routes/_auth+/auth.$provider.ts',
		lngs: [
			{ id: 'en', path: 'auth/:provider' },
			{ id: 'nl', path: 'auth/:provider' },
		],
	},
	{
		id: 'authProviderCallback',
		path: 'routes/_auth+/auth.$provider.callback.ts',
		lngs: [
			{ id: 'en', path: 'auth/:provider/callback' },
			{ id: 'nl', path: 'auth/:provider/callback' },
		],
	},
	{
		id: 'authProviderOnboarding',
		path: 'routes/_auth+/onboarding_.$provider.tsx',
		lngs: [
			{ id: 'en', path: 'onboarding/:provider' },
			{ id: 'nl', path: 'onboarding/:provider' },
		],
	},
	// Auth
	{
		id: 'signup',
		path: 'routes/_auth+/signup.tsx',
		lngs: [
			{ id: 'en', path: '/en/signup' },
			{ id: 'nl', path: '/nl/registreer' },
		],
	},
	{
		id: 'forgotPassword',
		path: 'routes/_auth+/forgot-password.tsx',
		lngs: [
			{ id: 'en', path: '/en/forgot-password' },
			{ id: 'nl', path: '/nl/wachtwoord-vergeten' },
		],
	},
	{
		id: 'resetPassword',
		path: 'routes/_auth+/reset-password.tsx',
		lngs: [
			{ id: 'en', path: '/en/reset-password' },
			{ id: 'nl', path: '/nl/reset-password' },
		],
	},
	{
		id: 'verifyEmail',
		path: 'routes/_auth+/verify.tsx',
		lngs: [
			{ id: 'en', path: '/en/verify' },
			{ id: 'nl', path: '/nl/verify' },
		],
	},
	{
		id: 'onboarding',
		path: 'routes/_auth+/onboarding.tsx',
		lngs: [
			{ id: 'en', path: '/en/onboarding' },
			{ id: 'nl', path: '/nl/onboarding' },
		],
	},
	{
		id: 'login',
		path: 'routes/_auth+/login.tsx',
		lngs: [
			{ id: 'en', path: '/en/login' },
			{ id: 'nl', path: '/nl/login' },
		],
	},
	{
		id: 'logout',
		path: 'routes/_auth+/logout.tsx',
		lngs: [
			{ id: 'en', path: 'logout' },
			{ id: 'nl', path: 'logout' },
		],
	},
	{
		id: 'settings',
		path: 'routes/settings+/index.tsx',
		lngs: [
			{ id: 'en', path: '/en/settings' },
			{ id: 'nl', path: '/nl/instellingen' },
		],
	},
	{
		id: 'termsOfService',
		path: 'routes/_marketing+/tos.tsx',
		lngs: [
			{ id: 'en', path: '/en/terms-of-service' },
			{ id: 'nl', path: '/nl/algemene-voorwaarden' },
		],
	},
	{
		id: 'privacy',
		path: 'routes/_marketing+/privacy.tsx',
		lngs: [
			{ id: 'en', path: '/en/privacy' },
			{ id: 'nl', path: '/nl/privacy' },
		],
	},
	{
		id: 'blogs',
		path: 'routes/blog+/index.tsx',
		lngs: [
			{ id: 'en', path: '/en/blog' },
			{ id: 'nl', path: '/nl/blog' },
		],
	},
	{
		id: 'individualBlog',
		path: 'routes/blog+/$blog.tsx',
		lngs: [
			{ id: 'en', path: '/en/blog/:param' },
			{ id: 'nl', path: '/nl/blog/:param' },
		],
	},
	{
		id: 'tests',
		path: 'routes/test+/index.tsx',
		lngs: [
			{ id: 'en', path: '/en/test' },
			{ id: 'nl', path: '/nl/test' },
		],
	},
	{
		id: 'individualTest',
		path: 'routes/test+/$test.tsx',
		lngs: [
			{ id: 'en', path: '/en/test/:param' },
			{ id: 'nl', path: '/nl/test/:param' },
		],
	},
	{
		id: 'completionChat',
		path: 'routes/chat+/completion.tsx',
		lngs: [
			{ id: 'en', path: '/completion' },
			{ id: 'nl', path: '/completion' },
		],
	},
	{
		id: 'chat',
		path: 'routes/chat+/index.tsx',
		lngs: [
			{ id: 'en', path: '/en/chat' },
			{ id: 'nl', path: '/nl/chat' },
		],
		children: [
			{
				id: 'individualChat',
				path: 'routes/chat+/$conversation.tsx',
				lngs: [
					{ id: 'en', path: ':param' },
					{ id: 'nl', path: ':param' },
				],
			},
		],
	},
	{
		id: 'services',
		path: 'routes/services+/index.tsx',
		lngs: [
			{ id: 'en', path: '/en/services' },
			{ id: 'nl', path: '/nl/diensten' },
		],
	},
	{
		id: 'checkoutPage',
		path: 'routes/_resources+/stripe/checkout.tsx',
		lngs: [
			{ id: 'en', path: 'nl/checkout' },
			{ id: 'nl', path: 'en/checkout' },
		],
	},
	// Landing pages
	{
		id: 'landingAIProfile',
		path: 'routes/_marketing+/_products+/aiProfile.tsx',
		lngs: [
			{ id: 'en', path: '/en/services/ai-profile' },
			{ id: 'nl', path: '/nl/diensten/ai-advies' },
		],
	},
	{
		id: 'landingChatbot',
		path: 'routes/_marketing+/_products+/chatbot.tsx',
		lngs: [
			{ id: 'en', path: '/en/services/chatbot' },
			{ id: 'nl', path: '/nl/diensten/chatbot' },
		],
	},
	{
		id: 'landingIpipNeo',
		path: 'routes/_marketing+/_products+/ipipNeo.tsx',
		lngs: [
			{ id: 'en', path: '/en/services/ipip' },
			{ id: 'nl', path: '/nl/diensten/ipip' },
		],
	},
	{
		id: 'profileOverview',
		path: 'routes/_profile+/profile.tsx',
		lngs: [
			{ id: 'en', path: '/en/profile' },
			{ id: 'nl', path: '/nl/profiel' },
		],
		children: [
			// Big Five
			{
				id: 'profileBigFiveOverview',
				path: 'routes/_profile+/profile/bigFive/overview.tsx',
				lngs: [
					{ id: 'en', path: 'big-five/overview' },
					{ id: 'nl', path: 'grote-vijf/overzicht' },
				],
			},
			{
				id: 'profileBigFiveAgreeableness',
				path: 'routes/_profile+/profile/bigFive/agreeableness.tsx',
				lngs: [
					{ id: 'en', path: 'big-five/agreeableness' },
					{ id: 'nl', path: 'grote-vijf/meegaandheid' },
				],
			},
			{
				id: 'profileBigFiveConscientiousness',
				path: 'routes/_profile+/profile/bigFive/conscientiousness.tsx',
				lngs: [
					{ id: 'en', path: 'big-five/conscientiousness' },
					{ id: 'nl', path: 'grote-vijf/zorgvuldigheid' },
				],
			},
			{
				id: 'profileBigFiveExtraversion',
				path: 'routes/_profile+/profile/bigFive/extraversion.tsx',
				lngs: [
					{ id: 'en', path: 'big-five/extraversion' },
					{ id: 'nl', path: 'grote-vijf/extraversie' },
				],
			},
			{
				id: 'profileBigFiveNeuroticism',
				path: 'routes/_profile+/profile/bigFive/neuroticism.tsx',
				lngs: [
					{ id: 'en', path: 'big-five/neuroticism' },
					{ id: 'nl', path: 'grote-vijf/neuroticisme' },
				],
			},
			{
				id: 'profileBigFiveOpenness',
				path: 'routes/_profile+/profile/bigFive/openness.tsx',
				lngs: [
					{ id: 'en', path: 'big-five/openness' },
					{ id: 'nl', path: 'grote-vijf/openheid' },
				],
			},
			{
				id: 'profileBigFiveTest',
				path: 'routes/_profile+/profile/bigFive/test.tsx',
				lngs: [
					{ id: 'en', path: 'big-five/test' },
					{ id: 'nl', path: 'grote-vijf/test' },
				],
			},
			// general
			{
				id: 'profileGeneral',
				path: 'routes/_profile+/profile/personal/general.tsx',
				lngs: [
					{ id: 'en', path: 'personal/general' },
					{ id: 'nl', path: 'persoonlijk/algemeen' },
				],
			},
			{
				id: 'profileIntake',
				path: 'routes/_profile+/profile/personal/intake.tsx',
				lngs: [
					{ id: 'en', path: 'personal/intake' },
					{ id: 'nl', path: 'persoonlijk/intake' },
				],
			},
			// Resources
			{
				id: 'profileResourceContact',
				path: 'routes/_profile+/profile/resources/contact.tsx',
				lngs: [
					{ id: 'en', path: 'resources/contact' },
					{ id: 'nl', path: 'hulpbronnen/contact' },
				],
			},
			{
				id: 'profileResourceNewFeature',
				path: 'routes/_profile+/profile/resources/new-feature.tsx',
				lngs: [
					{ id: 'en', path: 'resources/new-feature' },
					{ id: 'nl', path: 'hulpbronnen/nieuwe-functionaliteit' },
				],
			},
			{
				id: 'profileResourceProfessionalHelp',
				path: 'routes/_profile+/profile/resources/professional-help.tsx',
				lngs: [
					{ id: 'en', path: 'resources/professional-help' },
					{ id: 'nl', path: 'hulpbronnen/professionele-hulp' },
				],
			},
		],
	},
	// Forms
	{
		id: 'wiserSession',
		path: 'routes/_forms+/session.tsx',
		lngs: [
			{ id: 'en', path: '/en/session' },
			{ id: 'nl', path: '/nl/session' },
		],
	},
	// API Routes
	{
		id: 'MediaProxy',
		path: 'routes/_api+/media.$title.tsx',
		lngs: [
			{ id: 'en', path: '/media/:title' },
			{ id: 'nl', path: '/media/:title' },
		],
	},
	{
		id: 'Proxy',
		path: 'routes/_api+/proxy.tsx',
		lngs: [
			{ id: 'en', path: '/proxy' },
			{ id: 'nl', path: '/proxy' },
		],
	},
	{
		id: 'HideTosBanner',
		path: 'routes/_api+/hide-tos-banner.tsx',
		lngs: [
			{ id: 'en', path: '/hide-tos-banner' },
			{ id: 'nl', path: '/hide-tos-banner' },
		],
	},
	{
		id: 'ChangeLanguage',
		path: 'routes/_api+/change-language.tsx',
		lngs: [
			{ id: 'en', path: '/change-language' },
			{ id: 'nl', path: '/change-language' },
		],
	},
	{
		id: 'healthCheck',
		path: 'routes/resources+/healthcheck.tsx',
		lngs: [
			{ id: 'en', path: '/resources/healthcheck' },
			{ id: 'nl', path: '/resources/healthcheck' },
		],
	},
	{
		id: 'createCheckout',
		path: 'routes/_resources+/stripe/create-checkout.ts',
		lngs: [
			{ id: 'en', path: '/create-checkout' },
			{ id: 'nl', path: '/create-checkout' },
		],
	},
	{
		id: 'paymentWebhook',
		path: 'routes/_resources+/stripe/webhook.ts',
		lngs: [
			{ id: 'en', path: '/webhook' },
			{ id: 'nl', path: '/webhook' },
		],
	},
	// Error pages
	{
		id: '404',
		path: 'routes/$.tsx',
		lngs: [
			{ id: 'en', path: '*' },
			{ id: 'nl', path: '/nl/*' },
		],
	},
]

export default customRoutes
