import { Link } from '@remix-run/react'
import { type RemixLinkProps } from '@remix-run/react/dist/components.js'
import { generatePath } from '@remix-run/router'
import customRoutes from '../customRoutes.js'

export type InternalRoutes =
	| 'homeRoot'
	| 'homeNew'
	| 'bigFive'
	| 'usersProfile'
	| 'usersProfileNotes'
	| 'login'
	| 'logout'
	| 'signup'
	| 'forgotPassword'
	| 'resetPassword'
	| 'verifyEmail'
	| 'onboarding'
	| 'intake'
	| 'privacy'
	| 'termsOfService'
	| 'contactWiser'
	| 'aboutWiser'
	| 'blogs'
	| 'individualBlog'
	| 'tests'
	| 'individualTest'
	| 'chat'
	| 'individualChat'
	| 'services'
	| '404'
	| 'settings'
	| 'pricing'
	| 'landingAIProfile'
	| 'landingChatbot'
	| 'landingIpipNeo'
	| 'stripe'
	| 'profileOverview'
	| 'profileGeneral'
	| 'profileIntake'
	| 'profileResourceContact'
	| 'profileResourceNewFeature'
	| 'profileResourceProfessionalHelp'
	| 'profileBigFiveOverview'
	| 'profileBigFiveAgreeableness'
	| 'profileBigFiveConscientiousness'
	| 'profileBigFiveExtraversion'
	| 'profileBigFiveNeuroticism'
	| 'profileBigFiveOpenness'
	| 'profileBigFiveTest'

interface LocaleLinkProps
	extends Omit<RemixLinkProps, 'to'>,
		React.RefAttributes<HTMLAnchorElement> {
	route: InternalRoutes
	params?: Object
	searchParams?: string
	isChildOf?: InternalRoutes
}

interface getLinkByRoute {
	linkRouteId: InternalRoutes
	locale: string | undefined
	params?: Object
	searchParams?: string
	isChildOf?: InternalRoutes | ''
}

export function getLinkByRoute({
	linkRouteId,
	locale = 'en',
	params,
	searchParams,
	isChildOf,
}: getLinkByRoute) {
	let fullPath = ''

	if (isChildOf) {
		const parentRoute = customRoutes.find(r => r.id === isChildOf)
		const parentLocalPath = parentRoute?.lngs.find(l => l.id === locale)?.path
		const childRoute = parentRoute?.children?.find(c => c.id === linkRouteId)
		const childLocalPath = childRoute?.lngs.find(l => l.id === locale)?.path

		if (parentLocalPath && childLocalPath) {
			fullPath = `${parentLocalPath}/${childLocalPath}`
		} else {
			throw new Error(`
		  Route error:
		  Parent route ID: ${isChildOf}
		  Child route ID: ${linkRouteId}
		  locale: ${locale}
		`)
		}
	} else {
		const route = customRoutes.find(r => r.id === linkRouteId)
		const localPath = route?.lngs.find(l => l.id === locale)?.path
		if (route && localPath) {
			fullPath = localPath
		} else {
			throw new Error(`
		  Route error:
		  route: ${linkRouteId}
		  locale: ${locale}
		`)
		}
	}

	const pathWithParams = params ? generatePath(fullPath, params) : fullPath
	const finalPath = searchParams
		? `${pathWithParams}?${searchParams}`
		: pathWithParams

	return finalPath
}

export function LocaleLink({
	route,
	params,
	lang,
	onClick,
	searchParams,
	className,
	isChildOf,
	...rest
}: LocaleLinkProps) {
	try {
		let to = getLinkByRoute({
			linkRouteId: route,
			locale: lang,
			params,
			searchParams,
			isChildOf,
		})

		return (
			<Link to={to} onClick={onClick} className={className} {...rest}>
				{rest.children}
			</Link>
		)
	} catch (err) {
		console.error(err)
		return <p>Error with Link</p>
	}
}
