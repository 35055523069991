import { useMatches } from '@remix-run/react'

export function useCurrentLanguage() {
	const matches = useMatches()

	const nonRootMatch = matches.find(m => m.id !== 'root')

	let urlLanguage = ''
	if (nonRootMatch) {
		;[urlLanguage] = nonRootMatch.id.split('-')
	}
	return urlLanguage
}
